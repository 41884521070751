import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Header from '../components/Header';

import {
  SectionComponent,
  ParagraphComponent,
  ButtonComponent,
  HeadlineComponent,
} from '../styles/StyledComponents';
import SEO from '../utils/seo';
import Layout from '../layout/Layout';

export const Container = styled.div`
  display: flex;
  margin-top: ${({ marginTop }) => marginTop || 0};

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const DescriptionContainer = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.primary};
  min-height: 640px;
  display: flex;
  flex-direction: column;
  align-items: ${({ left }) => (left ? 'flex-start' : 'flex-end')};
  justify-content: center;
  text-align: ${({ left }) => (left ? 'left' : 'right')};

  @media screen and (max-width: 768px) {
    text-align: center;
    padding-bottom: 3rem;
  }
`;

export const HeadlineComponentLight = styled.h2`
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 3rem;
  color: ${({ theme, color }) => color || theme.colors.primary};
  padding: 0.5rem 2rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.green};

  @media screen and (max-width: 768px) {
  border: none;
  border-top: 1px solid ${({ theme }) => theme.colors.green};
    text-align: center;
    margin-bottom: 1rem;
  }
`;

export const HeadlineComponentDark = styled.h2`
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 1rem;
  color: ${({ theme, color }) => color || theme.colors.primary};
`;

export const LightParagraph = styled.p`
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  font-size: 1rem;
  font-weight: 300;
  padding: 0.5rem 2rem;
`;

export const FooterButton = styled.div`
  padding-top: 1.5rem;
`;
export const Image = styled.div`
  flex: 1;
  background-color: ${({ theme }) => 'white'};
`;

export const Footer = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

function PartnersPage() {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "about-background.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      about1: file(relativePath: { eq: "about-1.jpg" }) {
        childImageSharp {
          fixed(width: 640, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      about2: file(relativePath: { eq: "about-2.jpg" }) {
        childImageSharp {
          fixed(width: 640, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      about3: file(relativePath: { eq: "about-3.jpg" }) {
        childImageSharp {
          fixed(width: 640, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO
        title="About Us"
        link="https://teampoker.com/about"
        description="TeamPoker® is “The Patented Card Game Process” for the Tournament Poker Team Sports Model."
      />
      <Header image={data.background.childImageSharp.fluid} text="ABOUT US" />
      <SectionComponent mg="1.5rem">
        <HeadlineComponent>We're Team Poker®</HeadlineComponent>
        <ParagraphComponent>
          TeamPoker® is “The Patented Card Game Process” for the Tournament
          Poker Team Sports Model.
        </ParagraphComponent>
        <Container marginTop="1.5rem" id="scoring">
          <Image>
            <Img
              style={{ display: 'block', width: '100%', height: '100%' }}
              fixed={data.about2.childImageSharp.fixed}
            />
          </Image>
          <DescriptionContainer left>
            <HeadlineComponentLight color="white">
              Individual & Team Scoring
            </HeadlineComponentLight>
            <LightParagraph>
              Tournament Directors can structure any poker game with TeamPoker®
              so that both Individuals and Teams win a percentage of the prize
              pool. Any split is possible from 0-100%, either way. The inverse
              of the individual finish positions determines the Global Points
              awarded for Team Point Scores that determine team finish positions
              and payouts.The team points ‘tie breaker’ is the highest
              Individual Finish Position.
            </LightParagraph>
            <LightParagraph>
              Global Points allow Team Handicapping and are the root of Standard
              Team Ranking for the WORLD POKER TEAM CHAMPIONSHIP™ - SERIES
              Ranking.
            </LightParagraph>
            <LightParagraph>
              A second option for Team Scoring is Carry-The-Flog, where only the
              top individual finish positions determine team payout. The top
              individual team player Carries-The-Flag for team payout, and there
              is only one team payout per team.
            </LightParagraph>
            <LightParagraph>
              Both Individuals and Teams are Ranked by the TP Global Point
              System™ that determines finish positions, award, and can be used
              for the basis of the optional Team Handicapping System.
            </LightParagraph>
          </DescriptionContainer>
        </Container>
        <Container id="seating">
          <DescriptionContainer>
            <HeadlineComponentLight color="white">
              TeamPoker® Seating Protocol
            </HeadlineComponentLight>
            <LightParagraph>
              Tournament Directors structure and publish their Team Seating
              Requirements along with the typical tournament protocols of time
              periods, blind structure, etc. for running TeamPoker® Tournaments.
            </LightParagraph>
            <LightParagraph>
              TP NON-CONTACT: The preferred default method of seating is
              No-Contact, where teammates are not allowed together with
              opponents on the same table in tournaments. Teammates may play
              together or heads up during tournament table consolidation, but
              never on same table with opponents.
            </LightParagraph>
            <LightParagraph>
              TP FULL-CONTACT: The Full-Contact rule is the Standard Random
              Seating of Players and allows teammates on the same table with
              opponents. Considered a Pro-Seating Option, there is technically
              no possibility of collusion with Full-Contact as teammates are
              known and identified.
            </LightParagraph>
            <LightParagraph>
              TP HYBRID SEATING PROTOCOL: Using TeamPoker® you can switch from
              Non-Contact to Full Contact at any time and it can be used at the
              end of tournament, as example, to conserve tables.
            </LightParagraph>
          </DescriptionContainer>
          <Image>
            <Img
              style={{ display: 'block', width: '100%', height: '100%' }}
              fixed={data.about1.childImageSharp.fixed}
            />
          </Image>
        </Container>
        <Container id="handicapping">
          <Image>
            <Img
              style={{ display: 'block', width: '100%', height: '100%' }}
              fixed={data.about3.childImageSharp.fixed}
            />
          </Image>
          <DescriptionContainer left>
            <HeadlineComponentLight color="white">
              TeamPoker® Handicap
            </HeadlineComponentLight>
            <LightParagraph>
              TeamPoker® allows the Tournament Director to structure any sized
              team event, and, team size can vary in the same tournament using
              the TP Handicap.
            </LightParagraph>
            <LightParagraph>
              The Handicap is the ratio of the max team size allowed divided by
              the actual size within the minimum allowed by tournament
              structure. This ratio is multiplied by the Teams Global Points
              (inverse of finish position count); and, Team Points are compared
              for Team Finish Position and Prize Pool Payout.
            </LightParagraph>
            <LightParagraph>
              The team score tie breaker is always the team with the highest
              Individual Finish Position.
            </LightParagraph>
            <LightParagraph>
              CLASS TEAMS: The TP Handicap system allows large “CLASS TEAMS” of
              varying size to compete as well in the same Tournament. Countries,
              Universities, or Women Versus Men as example can compete in the
              same event and overlay the basic Individual and/or Team Structure.
              Also, this patented Team Gaming System can be used for eSports.
            </LightParagraph>
            <LightParagraph>
              The TeamPoker® Handicap and Scoring System is the new paradigm in
              tournament poker and gaming events where Individuals, Teams, and
              Class Teams can handicap their skills (Reverse Handicap) and
              compete for larger portion of glory and prize pool.
            </LightParagraph>
          </DescriptionContainer>
        </Container>
        <Footer>
          <HeadlineComponentDark>
            Help us make Poker a Team Sport!
          </HeadlineComponentDark>
          <Link to="/contact">
          <ButtonComponent>Learn More</ButtonComponent>
          </Link>
        </Footer>
      </SectionComponent>
    </Layout>
  );
}

export default PartnersPage;
